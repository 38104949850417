import { css } from '@emotion/css';
import Image from 'next/image';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { Curve, Grid, Section, Surface } from 'site-react/components/page';
import {
  Heading,
  MaterialIcon,
  Paragraph,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import useLocalStorage from 'site-react/hooks/useLocalStorage';
import theme from 'site-react/theme';

import ProductFeature from './../ProductFeature/ProductFeature';

const contentStyle = css`
  @media (min-width: ${theme.breakpoints.md}px) {
    text-align: center;
    grid-column-end: span 4;
  }

  @media (min-width: ${theme.breakpoints.lg}px) {
    text-align: left;
    grid-column-end: span 4;
  }
`;

const labelStyle = css`
  font-family: var(--font-family-monospaced);
  font-size: var(--font-size-xxs); /* 12px */
  font-weight: var(--font-weight-medium);
  letter-spacing: 1px;
  line-height: 1.3333px;
  text-transform: uppercase;
  color: var(--color-brandprimary);

  margin-top: 10px;
`;

const trustpilotContentWrapperStyle = css`
  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: center;

  @media (min-width: ${theme.breakpoints.md}px) {
    justify-content: flex-start;
  }
`;

const PermanentOfficeSearch = () => {
  const [isFullTimeSearchFormCompleted] = useLocalStorage(
    'fullTimeSearchFormCompleted',
  );

  const fullTimeEnquiryFormUrl = new URL(
    '/full-time-office-enquiry',
    getAppUrl(config),
  );
  fullTimeEnquiryFormUrl.searchParams.set('source', 'homepage');

  return (
    <Surface backgroundColor="gradient-pinkblue">
      <Curve
        bottomColor="transparent"
        height="lg"
        topColor="white"
        type="wave-inverted"
      />
      <Section verticalPadding="xxl">
        <ProductFeature
          image={
            <Image
              alt="illustration of a permanent office"
              height={574}
              loader={imgixLoader}
              src={getImgixPathWithParameters(
                '/site/homepage/permanent-office-search/permanent-office-search-hero.png',
                {},
              )}
              style={{
                height: 'auto',
                objectFit: 'cover',
                width: '100%',
              }}
              width={600}
            />
          }
        >
          <h3 className={theme.largeLabel}>Permanent Office Search</h3>
          <Heading type="title1">
            Taking the hassle out of finding your perfect office
          </Heading>
          <VerticalSpacing size="md" />
          <Paragraph type="content2">
            With over <b> 25 years of combined experience,</b> our team of
            experts places a company in their new home every three hours.
            They’re friendly, super responsive and work hard to get the best
            deals.
          </Paragraph>

          <Grid>
            <div className={contentStyle}>
              <div>
                <MaterialIcon iconType="arrow_circle_down" />
              </div>
              <b> up to 15%</b> <br />
              discount
            </div>
            <div className={contentStyle}>
              <div>
                <MaterialIcon iconType="location_on" />
              </div>
              <b> 100%</b> <br />
              Market coverage
            </div>
            <div className={contentStyle}>
              <div>
                <MaterialIcon iconType="schedule" />
              </div>
              <b> 15 min</b> <br />
              <abbr title="Average">Av.</abbr> response time
            </div>
          </Grid>
          <VerticalSpacing size="xl" />
          <Grid>
            <Grid.Item colSpan="9">
              <LinkAsButton
                analyticsMetadata={{
                  data_analytics_id:
                    'home-page-start-an-office-search-cta-permanent-office',
                  location: 'hero',
                }}
                href={
                  isFullTimeSearchFormCompleted
                    ? '/office-space-london'
                    : fullTimeEnquiryFormUrl
                }
                isBlock
                name="home-hero-connect"
                styleAtSmall="auto"
              >
                Start an office search
              </LinkAsButton>
            </Grid.Item>
            <Grid.Item align="start" colSpan="12">
              <Paragraph type="content3">
                ”We couldn’t have had a better experience with Hubble... After
                one day we found our perfect home. Could not recommend more!”{' '}
              </Paragraph>
            </Grid.Item>
          </Grid>
          <VerticalSpacing size="sm" />
          <div className={trustpilotContentWrapperStyle}>
            <div className={labelStyle}>-TrustPilot Review</div>
            <Image
              alt="Trustpilot 5 start review"
              height={16}
              loader={imgixLoader}
              src={getImgixPathWithParameters(
                '/site/homepage/permanent-office-search/trustpilot.png',
                {},
              )}
              width={140}
            />
          </div>
          <VerticalSpacing size="xxxl" />
        </ProductFeature>
      </Section>
      <Curve
        bottomColor="brandsecondary"
        height="lg"
        topColor="transparent"
        type="wave"
      />
    </Surface>
  );
};

export default PermanentOfficeSearch;
